const redirect = (href) => (window.location.href = href);

const refreshPage = () => window.location.reload();

/**
 * handle the http request protocol,
 * replace the http protocol as per standard If somebody provided it incorrect.
 */
const handleUrlProtocol = (url) => {
  if (
    !url ||
    typeof url === 'object' ||
    (typeof url === 'string' && url.trim().length === 0)
  ) {
    return '#';
  }
  return `//${url.split('//').pop()}`;
};

/**
 * Return the query string parameters,
 * If any param key/name is provided, the only that param's value will be returned
 * if not, all the params will be return.
 */
function getQueryParam(key) {
  if (key) {
    return new URLSearchParams(window.location.search).get(key);
  }

  return Object.fromEntries(new URLSearchParams(window.location.search).entries());
}

/**
 * Encode the form inputs into url encoded string.
 */
function encodeForm(data) {
  return Object.entries(data)
    .filter(
      ([key, value]) =>
        !(
          value === undefined ||
          value === null ||
          (typeof value === 'string' &&
            (value.trim() === 'null' || value.trim() === 'undefined'))
        )
    )
    .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
    .join('&');
}

/**
 * Exports methods
 */

export { refreshPage, redirect, handleUrlProtocol, getQueryParam, encodeForm };
