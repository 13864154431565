import axios from 'axios';

const setAccessToken = (token) => {
  // if token is present, apply the token to every request
  // delete auth header otherwise.
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

// Attach auctionId to requests
const setAuctionId = (auctionId) => {
  if (auctionId) {
    axios.defaults.headers.common.auctionId = auctionId;
  } else {
    delete axios.defaults.headers.common.auctionId;
  }
};

axios.interceptors.request.use(async (request) => {
  if (request.url.includes('api/psa/v1') || request.url.includes('api/auction')) {
    return request;
  }

  return {
    ...request,
    url: request.url
      .replace('v1/', `api/psa/v1/auction/${request.headers.common.auctionId}/`)
      .replace('site/', `api/psa/v1/site/${request.headers.common.auctionId}/`)
      .replace(':auctionId', request.headers.common.auctionId),
  };
});

/**
 * Export methods
 */
export { setAccessToken, setAuctionId };
