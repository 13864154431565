import { useState, useEffect } from 'react';

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    /// Handler to call on window resize,
    /// Set window width/height to state.

    function handleResize() {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    /// Add event listener.
    window.addEventListener('resize', handleResize);

    /// Call handler right away so state gets updated with initial window size.
    handleResize();

    /// Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [screenSize];
};
