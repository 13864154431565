import React from 'react';
import { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from './store/configureStore';

import AdminRoutes from './router/AdminRoutes';
import FrontendRoutes from './router/FrontendRoutes';

import { getQueryParam, getAccessTokenFromLS } from './helpers';
import { getUserDetails, getSubDomain, logInUser } from './store/actions/cmsPageActions';

const App = () => {
  const accessToken = getAccessTokenFromLS();
  const auctionSlug = getSubDomain();
  const [isFetching, setIsFetching] = useState(true);
  const isAdminSite = auctionSlug === 'admin' || auctionSlug === 'app';

  useEffect(() => {
    const ssoToken = getQueryParam('pge-login');

    if (isAdminSite && ssoToken) {
      store.dispatch(logInUser(ssoToken, setIsFetching));
    }

    if (accessToken) {
      store.dispatch(getUserDetails(accessToken, setIsFetching));
    } else {
      setIsFetching(false);
    }
  }, []);

  /**
   * Avoid page rendering until got the response from server.
   */
  if (isFetching) {
    return null;
  }

  return (
    <Provider store={store}>{isAdminSite ? <AdminRoutes /> : <FrontendRoutes />}</Provider>
  );
};

export default App;
