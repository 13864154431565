import moment from 'moment-timezone';
import { isEmpty } from '../helpers';
import { DEFAULT_TIMEZONE, DEFAULT_DATE_FORMAT } from '../constants/type';

const __utc = (date, format, timezone = DEFAULT_TIMEZONE) => {
  return moment.tz(moment(date).format(format), timezone).utc().format(format);
};

const getCurrentDate = ({
  format = 'MM/DD/YYYY HH:mm',
  timezone = DEFAULT_TIMEZONE,
} = {}) => {
  return new Date(moment.tz(timezone).format(format));
};

const utcToLocal = (date) => {
  if (isEmpty(date)) {
    return null;
  }

  return moment.utc(date).toDate();
};

const utcToOtherTimezone = (date, timezone, format = DEFAULT_DATE_FORMAT) => {
  if (isEmpty(date)) {
    return null;
  }

  return moment
    .utc(date)
    .tz(timezone || DEFAULT_TIMEZONE)
    .format(format);
};

const displayStandardTimezone = (timezone = DEFAULT_TIMEZONE) => {
  const timezones = {
    'America/New_York': 'Eastern Time',
    'America/Los_Angeles': 'Pacific Time',
    'America/Chicago': 'Central Time',
    'America/Anchorage': 'Alaska Time',
    'America/Denver': 'Mountain Time',
    'America/Phoenix': 'Mountain Time (no DST)',
    'America/Adak': 'Hawaii-Aleutian Time',
    'Pacific/Honolulu': 'Hawaii-Aleutian Time (no DST)',
  };

  return timezones[timezone];
};

/**
 * Export methods
 */
export { __utc, getCurrentDate, utcToLocal, utcToOtherTimezone, displayStandardTimezone };
