import { isEmpty, isTrue } from '../../helpers';
import {
  LOGIN_SUCCESS,
  ORGANIZER_SWITCH,
  FRONTEND_REGISTRATION,
  ORGANIZER_REGISTRATION,
} from '../../constants/type';

const initialState = { isAuthenticated: false };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
    case ORGANIZER_SWITCH:
    case FRONTEND_REGISTRATION:
    case ORGANIZER_REGISTRATION:
      return {
        ...state,
        user: isEmpty(payload)
          ? null
          : {
              ...payload,
              isAdmin: isTrue(payload.isAdmin),
              isOrganizer: isTrue(payload.isOrganizer),
              isAssociate: isTrue(payload.isAssociate),
              isAuctionOwner: isTrue(payload.isAuctionOwner),
              hasAuctionAccess: isTrue(payload.hasAuctionAccess),
            },
        isAuthenticated: !isEmpty(payload),
      };

    default:
      return state;
  }
}
