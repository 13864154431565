import {
  SUB_DOMAIN_DETAIL,
  SHOW_FLASH_MESSAGE,
  USER_WINNIING_BIDS,
  SWITCH_ORGANIZER_MODAL,
} from '../../constants/type';

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_WINNIING_BIDS:
      return {
        ...state,
        userWinningBid: action.payload,
      };

    case SHOW_FLASH_MESSAGE:
      return {
        ...state,
        notification: action.payload,
      };

    case SUB_DOMAIN_DETAIL:
      const { configuration = {}, content = {}, ...siteDetail } = action.payload;

      return {
        ...state,
        siteDetail: {
          ...configuration,
          ...siteDetail,
        },
        configuration: {
          ...configuration,
          custom_labels: configuration?.custom_labels || {},
        },
      };

    case SWITCH_ORGANIZER_MODAL:
      return {
        ...state,
        isSwitchOrganizer: action.payload.isOpenModal,
      };

    default:
      return state;
  }
}
