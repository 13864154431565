import { combineReducers } from 'redux';
import cmsReducer from './cmsReducer';
import authReducer from './authReducer';
import cartReducer from './cartReducer';

export default combineReducers({
  cms: cmsReducer,
  auth: authReducer,
  cart: cartReducer,
});
