import axios from 'axios';
import { API_URL, LOGIN_SUCCESS } from '../../constants/type';
import {
  redirect,
  getAxios,
  postAxios,
  setAccessToken,
  setAccessTokenInLS,
  removeAccessTokenInLS,
} from '../../helpers';

axios.defaults.baseURL = API_URL;

const getSubDomain = () => {
  if (window.location.hostname === 'localhost') {
    return window.location.port === '5000' ? 'admin' : 'first-auction';
  }

  return window.location.hostname.split('.')[0];
};

const getStripePublicKey = () => {
  return getAxios('v1/stripe-key');
};

const getAuctionDetailById = () => {
  return getAxios('api/auction/get-auction-detail');
};

const getAuctionDetailBySlug = (slug) => {
  return postAxios('api/auction/get-subdomain-detail', { slug });
};

const fetchPageContent = (pageSlug) => {
  return getAxios(`api/auction/website/page-content/${pageSlug}`);
};

const getUserWinningBidsCount = () => {
  return getAxios('api/auction/user/winning-bid-count');
};

const getUserDetails = (token, setIsFetching) => {
  setAccessToken(token);

  return (dispatch) => {
    getAxios('api/auction/users')
      .then((user) => {
        dispatch({ type: LOGIN_SUCCESS, payload: user });
      })
      .catch(() => dispatch(logoutUser()))
      .finally(() => setIsFetching(false));
  };
};

const logInUser = (auth, setIsFetching) => {
  return (dispatch) => {
    postAxios('api/auction/organizer/sso', { auth })
      .then((res) => res.data)
      .then(({ token, ...user }) => {
        dispatch({ type: LOGIN_SUCCESS, payload: user });
        setAccessToken(token);
        setAccessTokenInLS(token);
        redirect('/dashboard');
      })
      .catch(() => dispatch(logoutUser()))
      .finally(() => setIsFetching(false));
  };
};

const logoutUser = () => {
  return (dispatch) => {
    setAccessToken(false);
    removeAccessTokenInLS();
    dispatch({ type: LOGIN_SUCCESS, payload: null });
    redirect('/');
  };
};

export {
  getSubDomain,
  getStripePublicKey,
  getAuctionDetailById,
  getAuctionDetailBySlug,
  fetchPageContent,
  getUserWinningBidsCount,
  getUserDetails,
  logInUser,
  logoutUser,
};
