import axios from 'axios';

import { API_URL } from '../constants/type';
import { encodeForm } from './querystring';
import { fileDownload } from './handleFileDownloader';

axios.defaults.baseURL = API_URL;

const axiosDownloadCSV = (url, file) => {
  return axios
    .get(url, {
      headers: { Accept: 'application/json', responseType: 'arraybuffer' },
    })
    .then(handleResponse)
    .then((reportData) => {
      fileDownload(reportData, file);
    })
    .catch(handleErrorResponse);
};

const getAxios = (url, otherConfig = {}) => {
  return axios
    .get(url, {
      headers: { Accept: 'application/json', ...otherConfig },
    })
    .then(handleResponse)
    .then((response) => response.data)
    .catch(handleErrorResponse);
};

const postAxios = (url, inputs) => {
  const request = {
    url: url,
    body: encodeForm(inputs),
    headers: { headers: { Accept: 'application/json' } },
  };

  return axios
    .post(request.url, request.body, request.headers)
    .then(handleResponse)
    .catch(handleErrorResponse);
};

const putAxios = (url, inputs) => {
  const request = {
    url: url,
    body: encodeForm(inputs),
    headers: { headers: { Accept: 'application/json' } },
  };

  return axios
    .put(request.url, request.body, request.headers)
    .then(handleResponse)
    .catch(handleErrorResponse);
};

const patchAxios = (url, inputs) => {
  const request = {
    url: url,
    body: encodeForm(inputs),
    headers: { headers: { Accept: 'application/json' } },
  };

  return axios
    .patch(request.url, request.body, request.headers)
    .then(handleResponse)
    .catch(handleErrorResponse);
};

const deleteAxios = (url, deleteId) => {
  return axios
    .delete(`${url}/${deleteId}`)
    .then(handleResponse)
    .catch(handleErrorResponse);
};

/// Handle the api response and throw an error in case of failed request

function handleResponse(response) {
  const { status, success, message, errors = {} } = response.data;

  if (status === false || success === false) {
    throw { status: false, message, errors };
  }

  return response.data;
}

/// Handle the api error response for failed requests.

function handleErrorResponse(error) {
  if (error.status === false) {
    throw error;
  } else if (error.response) {
    throw { ...error.response.data, status: false };
  } else {
    throw {
      status: false,
      message: 'Something went wrong, please try again!',
      errors: [],
    };
  }
}

/**
 * Export methods
 */
export {
  getAxios,
  postAxios,
  putAxios,
  patchAxios,
  deleteAxios,
  axiosDownloadCSV,
  handleResponse,
};
