import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const BidderRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated || false);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default BidderRoute;
