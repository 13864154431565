import React from 'react';
import { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { isEmpty, isTrue, setAuctionId } from '../helpers';
import { useNotification } from '../hooks';
import { getAuctionDetailById } from '../store/actions/cmsPageActions';

const AdminRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const [setResponse] = useNotification();
  const [invalidRequest, setInvalidRequest] = useState(false);
  const [hasAuctionAccess, setHasAuctionAccess] = useState(false);

  const user = useSelector((state) => state.auth.user || {});
  const currentAuctionId = useSelector((state) => (state.cms.siteDetail || {}).id);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated || false);

  const {
    computedMatch: { params: { auction_id: paramAuctionId } = {} },
  } = rest;

  /// Update the store with auction detail,
  /// if auction Id present in url and is different than store's auction Id.
  /// clear the existing auction' detail from store if auction Id not present in url.

  useEffect(() => {
    if (paramAuctionId) {
      if (paramAuctionId !== currentAuctionId) {
        getAuctionDetailById()
          .then((auction) => {
            setHasAuctionAccess(isTrue(auction?.hasAuctionAccess));
            updateStore(auction);
          })
          .catch((response) => {
            setInvalidRequest(true);
            updateStore({});
            setResponse(response);
          });
      }
    } else {
      if (currentAuctionId) updateStore({});
    }
  }, [paramAuctionId]);

  const isProcessing = () => !(paramAuctionId == currentAuctionId || invalidRequest);
  const isValidRequest = () => {
    return isAuthenticated === true && (!paramAuctionId || hasAuctionAccess);
  };

  const updateStore = ({ isAuctionOwner, hasAuctionAccess, ...auction }) => {
    dispatch({ type: 'SUB_DOMAIN_DETAIL', payload: auction });
    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: {
        ...user,
        ...(!isEmpty(user) && { isAuctionOwner, hasAuctionAccess }),
      },
    });
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        const {
          match: { params = {} },
        } = props;

        /// Attach auction ID to axios request headers if present in url.
        setAuctionId(params.auction_id);

        /// prevent component rendering until the Api call completed.
        if (isProcessing()) return null;

        return isValidRequest() ? (
          <Component
            {...props}
            {...params}
            auctionId={params.auction_id}
            isAdmin={isTrue(user?.isAdmin)}
          />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

export default AdminRoute;
