import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const FlashMessage = () => {
  const dispatch = useDispatch();
  const { message, status } = useSelector((state) => state.cms.notification || {});
  const [clearTimeoutId, setClearTimeoutId] = useState(null);

  useEffect(() => clearTimeout(clearTimeoutId), []);
  useEffect(() => {
    setClearTimeoutId(
      setTimeout(() => dispatch({ type: 'SHOW_FLASH_MESSAGE', payload: {} }), 3000)
    );
  }, [message]);

  return message
    ? ReactDOM.createPortal(
        <div
          className="feedback-wrap"
          style={{ backgroundColor: `${status ? 'var(--greenish-blue-color)' : '#c51300f7'}` }}
        >
          <div className="feedback">
            <p dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </div>,
        document.getElementById('__ac-flash-message')
      )
    : null;
};

export default FlashMessage;
