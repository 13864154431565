import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lazy, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { useAutoLogout } from '../hooks';
import { setAuctionId } from '../helpers';
import { getSubDomain, getAuctionDetailBySlug } from '../store/actions/cmsPageActions';

import BidderRoute from './BidderRoute';
import Spinner from '../components/common/spinner';
import FlashMessage from '../components/common/popup/GlobalFlashMessage';

const ResetPassword = lazy(() => import('../components/frontend-auth/ResetPassword'));
const HomePage = lazy(() => import('../components/frontend-home-page'));
const AboutUs = lazy(() => import('../components/frontend-pages/AboutUs'));

const NotFound = lazy(() => import('../components/frontend-auth/NotFound'));
const ContactUs = lazy(() => import('../components/frontend-pages/ContactUs'));
const ItemDetailPage = lazy(() => import('../components/frontend-item-detail-page'));
const TermsAndConditions = lazy(() => import('../components/frontend-pages/TermsAndConditions'));
const DonateNow = lazy(() => import('../components/frontend-donation-page/DonateNow'));

const ChangePassword = lazy(() => import('../components/frontend-bidder-settings/ChangePassword'));
const ProfileSettings = lazy(() => import('../components/frontend-bidder-settings/Profile'));
const SubscribeSmsSettings = lazy(() => import('../components/frontend-bidder-settings/SubscribeSms'));

const WinningItemsListWithoutLogin = lazy(() => import('../components/frontend-bidder-settings/WinningItemsListWithoutLogin'));
const WinningItemsList = lazy(() => import('../components/frontend-bidder-settings/WinningItemsList'));
const WinningItemsPayment = lazy(() => import('../components/frontend-payment-page/WinningItemsPayment'));
const BuyNowPayment = lazy(() => import('../components/frontend-payment-page/BuyNowPayment'));
const IndividualBidPayment = lazy(() => import('../components/frontend-payment-page/IndividualBidPayment'));
const OrderItemHistory = lazy(() => import('../components/frontend-order-item-history/OrderItemHistory'));

const FrontendRouter = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const subDomain = getSubDomain();
  const autoLogout = useAutoLogout();
  const [isValid, setIsValid] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => autoLogout(), []);

  useEffect(() => {
    getAuctionDetailBySlug(subDomain)
      .then((response) => response.data)
      .then((auction) => {
        setIsValid(true);
        setAuctionId(auction.id);
        dispatch({ type: 'SUB_DOMAIN_DETAIL', payload: auction });
      })
      .catch(() => {})
      .finally(() => setIsFetching(true));
  }, []);

  /**
   * Avoid page rendering until got the auction detail from server.
   */

  if (!isFetching)  return null;

  return (
    <BrowserRouter>
      <React.Suspense fallback={<Spinner />}>
        {isValid ? (
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/item-detail/:itemId" component={ItemDetailPage} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
            <Route exact path="/donate-now" component={DonateNow} />

            <Route
              exact
              path="/payment/:bidId"
              render={(props) =>
                isAuthenticated === true ? (
                  <WinningItemsList {...props} />
                ) : (
                  <WinningItemsListWithoutLogin {...props} />
                )
              }
            />

            <Route
              exact
              path="/user/winning-items"
              render={(props) =>
                isAuthenticated === true ? (
                  <WinningItemsList {...props} />
                ) : (
                  <WinningItemsListWithoutLogin {...props} />
                )
              }
            />

            <BidderRoute
              exact
              path="/user/subscribe-sms"
              component={SubscribeSmsSettings}
            />
            <BidderRoute exact path="/user/change-password" component={ChangePassword} />
            <BidderRoute
              exact
              path="/user/profile-settings"
              component={ProfileSettings}
            />

            <BidderRoute
              exact
              path="/checkout/auction/:auctionId"
              component={WinningItemsPayment}
            />
            <BidderRoute
              exact
              path="/checkout/buy-now/:itemId"
              component={BuyNowPayment}
            />

            <BidderRoute
              exact
              path="/checkout/individual/:winnerBidId"
              component={IndividualBidPayment}
            />

            <BidderRoute
              exact
              path="/user/order-item-history"
              component={OrderItemHistory}
            />

            <Route component={NotFound} />
          </Switch>
        ) : (
          <Route component={NotFound} />
        )}
      </React.Suspense>

      <FlashMessage />
    </BrowserRouter>
  );
};

export default FrontendRouter;