import Entity from 'he';
import { isEmpty } from './isEmpty';

const formatHtmlString = (htmlString) => {
  if (isEmpty(htmlString)) return '';

  if (htmlString.trim() !== Entity.decode(htmlString).trim()) {
    return formatHtmlString(Entity.decode(htmlString));
  }

  return htmlString;
};

/**
 * Export methods
 */
export { formatHtmlString };
