export const printProcessor = (processor) =>
  ({
    pge: 'PSA',
    Other: 'Other',
    stripe: 'Stripe',
    firstdata: 'First Data',
    first_data: 'First Data',
    authorize: 'Authorize.net',
    authorize_net: 'Authorize.net',
  }[processor] || processor);
