/// eg. 1,000.00
/// convert the amount into currency format.

function priceFormat(amount) {
  if (!amount || typeof amount === 'object') {
    return 0;
  }

  if (amount === '0' || amount === '0.00') {
    return '$0';
  }

  return `$${Number(amount.toString().replace(',', ''))
    .toFixed(2)
    .toLocaleString()
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}

/// convert to number
/// remove the non-numeric value from string

function toInt(number) {
  return +number || 0;
}

function formatToNumber(value, returnZeroIfEmpty = false) {
  if (/^[0-9.]+$/.test(value)) {
    return parseFloat(value).toFixed(0);
  } else {
    return returnZeroIfEmpty ? 0 : '';
  }
}

/// format to us phone number

function formatToPhone(phoneNumber, countryCode = '+1') {
  const phonenumber = ('' + phoneNumber).replace(countryCode, '').replace(/\D/g, '');

  if (phonenumber.length === 10) {
    return phonenumber.replace(/(\d{3})(\d{3})(\d{4})/, `${countryCode} ($1) $2-$3`);
  }

  if (phonenumber.length > 11) {
    return phonenumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
  }

  if (phonenumber.length > 10) {
    return phonenumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
  }

  return phonenumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

/**
 * Exports methods
 */
export { priceFormat, formatToPhone, toInt, formatToNumber };
