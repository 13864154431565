/// to check whether the given value is equivalent to true.

const isTrue = (value) =>
  (typeof value === 'boolean' && value === true) ||
  (typeof value === 'number' && value === 1) ||
  (typeof value === 'string' && (value === 'true' || value === '1'));

/**
 * Export methods
 */
export { isTrue };
