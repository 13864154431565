import { useState } from 'react';
import { isDecimal } from '../helpers';

export const useForm = (initialFValues = {}, validateOnChange = false, validate) => {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(initialFValues);

  const resetForm = () => {
    setErrors({});
    setValues(initialFValues);
  };

  /// Get the value of different form input type
  const getValue = (input) => {
    const { type } = input;

    if (type === 'checkbox') {
      return input.checked;
    } else if (type === 'select-multiple') {
      return Array.from(input.selectedOptions).map((o) => o.value);
    }

    return input.value;
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = getValue(e.target);

    if (validateOnChange && validate({ name, value })) {
      return false;
    }

    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  /**
   * Allow only positive integer value as input.
   */
  const handleNumberInputChange = (e) => {
    const { name, value } = e.target;

    if (/^[0-9]*$/.test(value)) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleDecimalInputChange = (e) => {
    const { name, value } = e.target;

    if (isDecimal(value)) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleFloatingInputChange = (e) => {
    const { name, value } = e.target;

    if (isDecimal(value, 3)) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  return {
    formInputs: values,
    values,
    setValues,
    errors,
    setErrors,
    resetForm,
    handleInputChange,
    handleNumberInputChange,
    handleDecimalInputChange,
    handleFloatingInputChange,
  };
};
