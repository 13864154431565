import React from 'react';
import { useSelector } from 'react-redux';
import { lazy,useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { useAutoLogout } from '../hooks';
import AdminRoute from './AdminRoute';
import Spinner from '../components/common/spinner';
import FlashMessage from '../components/common/popup/GlobalFlashMessage';

/// Admin specific routes
const AuctionManage = lazy(() => import('../components/auction-manage/AuctionManage'));
const AuctionUsersList = lazy(() => import('../components/auction-user/AuctionUsersList'));
const AuctionOrganizers = lazy(() => import('../components/auction-user/AuctionOrganizers'));
const AdminPromocode = lazy(() => import('../components/admin-promocode'));
const NewsAndNotificationList = lazy(() => import('../components/admin-news/NewsAndNotificationList'));
const NewsAndNotificationAdd = lazy(() => import('../components/admin-news/NewsAndNotificationAdd'));
const NewsAndNotificationUpdate = lazy(() => import('../components/admin-news/NewsAndNotificationUpdate'));
const AdminRequestFunds = lazy(() => import('../components/auction-request-funds/AdminRequestFunds'));

const PsaItemList = lazy(() => import('../components/psa-item/PsaItemList'));
const PsaItemCreate = lazy(() => import('../components/psa-item/PsaItemCreate'));
const PsaItemImages = lazy(() => import('../components/psa-item/PsaItemImages'));
const PsaItemUpdate = lazy(() => import('../components/psa-item/PsaItemUpdate'));
const PsaPaidItemReport = lazy(() => import('../components/psa-items-admin-report/PsaPaidItemReport'));

const AdminSuppliersList = lazy(() => import('../components/admin-supplier/AdminSuppliersList'));
const AdminSupplierCreate = lazy(() => import('../components/admin-supplier/AdminSupplierCreate'));
const AdminSupplierUpdate = lazy(() => import('../components/admin-supplier/AdminSupplierUpdate'));

/// Organizer specific routes
const OrganizerDashboard = lazy(() => import('../components/auction-manage/OrganizerDashboard'));
const AccountSettings = lazy(() => import('../components/auction-user/AccountSettings'));
const AuctionCreate = lazy(() => import('../components/auction-manage/AuctionCreate'));
const AuctionCopyCreate = lazy(() => import('../components/auction-manage/AuctionCopyCreate'));
const ItemSponsorImage = lazy(() => import('../components/auction-item/ItemSponsorImage'));

/// Auction specific routes
const AuctionDashboard = lazy(() => import('../components/auction-dashboard'));
const AuctionUpdate = lazy(() => import('../components/auction-manage/AuctionUpdate'));
const AdminAuctionSettings = lazy(() => import('../components/auction-manage/AdminAuctionSettings'));

const AuctionSuppliersList = lazy(() => import('../components/auction-supplier/AuctionSuppliersList'));
const AuctionSupplierCreate = lazy(() => import('../components/auction-supplier/AuctionSupplierCreate'));
const AuctionSupplierUpdate = lazy(() => import('../components/auction-supplier/AuctionSupplierUpdate'));

const AuctionItemList = lazy(() => import('../components/auction-item/AuctionItemList'));
const AuctionItemCreate = lazy(() => import('../components/auction-item/AuctionItemCreate'));
const AuctionItemUpdate = lazy(() => import('../components/auction-item/AuctionItemUpdate'));
const AuctionItemImages = lazy(() => import('../components/auction-item/AuctionItemImages'));
const AuctionPsaItems = lazy(()=>import('../components/auction-item/AuctionPsaItems'));
const CopyPsaItem = lazy(()=>import('../components/auction-item/CopyPsaItem'));

const ManageAuctionCategory = lazy(() => import('../components/auction-category/ManageAuctionCategory'));
const AuctionRevenueExpenses = lazy(() => import('../components/auction-revenue-expenses'));

/// Auction Associate routes
const AuctionAssociate = lazy (()=> import('../components/auction-associate/AuctionAssociate'));

const AuctionPaymentOptions = lazy(() => import('../components/auction-payment/AuctionPaymentOptions'));
const AuctionReceiptSetup = lazy(() => import('../components/auction-payment/AuctionReceiptSetupDetail'));
const AuctionReceiptBannerUpload = lazy(() => import('../components/auction-payment/AuctionReceiptBannerUpload'));
const AuctionRequestFunds = lazy(() => import('../components/auction-request-funds/AuctionRequestFunds'));

const WebsiteConfigurations = lazy(() => import('../components/auction-website/WebsiteConfigurations'));
const AuctionWebsiteContent = lazy(() => import('../components/auction-website/WebsitePageContent'));
const WebsiteLogoImage = lazy(() => import('../components/auction-website/WebsiteLogoImage'));
const WebsiteBackgroundImages = lazy(() => import('../components/auction-website/WebsiteBackgroundImages'));
const WebsiteSponsorImages = lazy(() => import('../components/auction-website/WebsiteSponsorImages'));

const DonationSettings = lazy(() => import('../components/auction-donations/Settings'));
const DonationList = lazy(() => import('../components/auction-donations/List'));
const DonationInvoices = lazy(() => import('../components/auction-donations/DonationInvoices'));

const SmsMarketingGroups = lazy(() => import('../components/auction-sms/SmsMarketingGroups'));
const SmsMarketingGroupContacts = lazy(() => import('../components/auction-sms/SmsMarketingGroupContacts'));

const SmsTemplates = lazy(() => import('../components/auction-sms/SmsTemplates'));
const CreateSmsTemplate = lazy(() => import('../components/auction-sms/CreateSmsTemplate'));
const UpdateSmsTemplate = lazy(() => import('../components/auction-sms/UpdateSmsTemplate'));
const SmsHistory = lazy(() => import('../components/auction-sms/SmsHistory'));
const SentSmsList = lazy(() => import('../components/auction-sms/SentSmsList'));
const SendSmsNotification = lazy(() => import('../components/auction-sms/SendSmsNotification'));

const AdminSmsTemplates = lazy(() => import('../components/auction-sms/AdminSmsTemplates'));
const AdminCreateSmsTemplate = lazy(() => import('../components/auction-sms/AdminCreateSmsTemplate'));
const AdminUpdateSmsTemplate = lazy(() => import('../components/auction-sms/AdminUpdateSmsTemplate'));
const AdminSendSmsNotification = lazy(() => import('../components/auction-sms/AdminSendSmsNotification'));
const ContactUsQueries = lazy(() => import('../components/admin-contacts/ContactUsQueries'));
const EditContactQuery = lazy(() => import('../components/admin-contacts/EditContactQuery'));

const WinningItemPaymentList = lazy(() => import('../components/auction-winning-item-payment-list'));
const ItemBidCountReport = lazy(() => import('../components/auction-items-bid-list/ItemBidCountReport'));
const ItemBidReportList = lazy(() => import('../components/auction-items-bid-list/ItemBidReportList'));
const DonationReport = lazy(() => import('../components/auction-donation-report'));

const ForgotPassword = lazy(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../components/auth/ResetPassword'));
const Registration = lazy(() => import('../components/auth/Registration'));
const Login = lazy(() => import('../components/auth/Login'));

const Router = () => {
  const autoLogout = useAutoLogout();
  const { isAdmin } = useSelector((state) => state.auth.user || {});

  useEffect(() => autoLogout(), []);

  return (
    <BrowserRouter>
      <React.Suspense fallback={<Spinner />}>

        {/* prettier-ignore */}
        <Switch>

          {isAdmin && [
            <AdminRoute exact path="/admin/auction-list" component={AuctionManage} />,
            <AdminRoute exact path="/admin/manage-organizers" component={AuctionOrganizers} />,
            <AdminRoute exact path="/admin/promocode" component={AdminPromocode} />,
            <AdminRoute exact path="/admin/news-and-notification" component={NewsAndNotificationList} />,
            <AdminRoute exact path="/admin/create-news" component={NewsAndNotificationAdd} />,
            <AdminRoute exact path="/admin/update-news/:id" component={NewsAndNotificationUpdate} />,
            <AdminRoute exact path="/admin/requested-funds" component={AdminRequestFunds} />,
            <AdminRoute exact path="/admin/send-sms-notification" component={AdminSendSmsNotification} />,
            <AdminRoute exact path="/admin/site-queries" component={ContactUsQueries} />,
            <AdminRoute exact path="/admin/edit-query/:id" component={EditContactQuery} />,
            <AdminRoute exact path="/admin/sms-templates" component={AdminSmsTemplates} />,
            <AdminRoute exact path="/admin/create-sms-template" component={AdminCreateSmsTemplate} />,
            <AdminRoute exact path="/admin/update-sms-template/:templateId" component={AdminUpdateSmsTemplate} />,
            <AdminRoute exact path="/admin/manage-psa-item" component={PsaItemList} />,
            <AdminRoute exact path="/admin/create-psa-item" component={PsaItemCreate} />,
            <AdminRoute exact path="/admin/psa-item-images/:id" component={PsaItemImages} />,
            <AdminRoute exact path="/admin/update-psa-item/:id" component={PsaItemUpdate} />,
            <AdminRoute exact path="/admin/psa-items-report" component={PsaPaidItemReport} />
          ]}

          <AdminRoute exact path="/admin/manage-auction-user" component={AuctionUsersList} />

          <AdminRoute exact path="/dashboard" component={OrganizerDashboard} />
          <AdminRoute exact path="/account-settings" component={AccountSettings} />
          <AdminRoute exact path="/auction-create" component={AuctionCreate} />
          <AdminRoute exact path="/auction-copy-create" component={AuctionCopyCreate} />

          <AdminRoute
            exact
            path="/secure/dashboard/:auction_id"
            component={AuctionDashboard}
          />

          {isAdmin && (
            <AdminRoute
              exact
              path="/secure/admin-auction-settings/:auction_id"
              component={AdminAuctionSettings}
            />
          )}

          <AdminRoute
            exact
            path="/admin/auction-update/:auction_id"
            component={AuctionUpdate}
          />

          <AdminRoute
            exact
            path="/admin/manage-auction-supplier/:auction_id"
            component={AuctionSuppliersList}
          />
          <AdminRoute
            exact
            path="/admin/create-auction-supplier/:auction_id"
            component={AuctionSupplierCreate}
          />
          <AdminRoute
            exact
            path="/admin/update-auction-supplier/:auction_id/:id"
            component={AuctionSupplierUpdate}
          />
          
          <AdminRoute
            exact
            path="/admin/manage-admin-supplier"
            component={AdminSuppliersList}
          />
          <AdminRoute
            exact
            path="/admin/create-admin-supplier"
            component={AdminSupplierCreate}
          />
          <AdminRoute
            exact
            path="/admin/update-admin-supplier/:id"
            component={AdminSupplierUpdate}
          />

          <AdminRoute
            exact
            path="/admin/manage-auction-item/:auction_id"
            component={AuctionItemList}
          />
          <AdminRoute
            exact
            path="/secure/create-auction-item/:auction_id"
            component={AuctionItemCreate}
          />
          <AdminRoute
            exact
            path="/admin/update-auction-item/:auction_id/:id"
            component={AuctionItemUpdate}
          />
          <AdminRoute
            exact
            path="/admin/auction-item-images/:auction_id/:id"
            component={AuctionItemImages}
          />

          <AdminRoute
            exact
            path="/secure/item-sponsor/:auction_id/:id"
            component={ItemSponsorImage}
          />

          <AdminRoute
            exact
            path="/secure/auction-psa-items/:auction_id"
            component={AuctionPsaItems}
          />

          <AdminRoute
            exact
            path="/secure/copy-psa-item/:auction_id/:psaItemId"
            component={CopyPsaItem}
          />

          <AdminRoute
            exact
            path="/admin/manage-auction-category/:auction_id"
            component={ManageAuctionCategory}
          />

          <AdminRoute
            exact
            path="/admin/auction-revenue-expenses/:auction_id"
            component={AuctionRevenueExpenses}
          />

          <AdminRoute 
            exact 
            path="/secure/associate-login/:auction_id" 
            component={AuctionAssociate} 
          />

          <AdminRoute
            exact
            path="/secure/auction-payment-options/:auction_id"
            component={AuctionPaymentOptions}
          />

          <AdminRoute
            exact
            path="/admin/auction-receipt-setup/:auction_id"
            component={AuctionReceiptSetup}
          />

          <AdminRoute
            exact
            path="/admin/auction-receipt-banner-upload/:auction_id"
            component={AuctionReceiptBannerUpload}
          />
          <AdminRoute
            exact
            path="/secure/request-funds/:auction_id"
            component={AuctionRequestFunds}
          />

          <AdminRoute
            exact
            path="/secure/sms-marketing-groups/:auction_id"
            component={SmsMarketingGroups}
          />
          <AdminRoute
            exact
            path="/secure/sms-marketing-group-contacts/:auction_id/:groupId"
            component={SmsMarketingGroupContacts}
          />

          <AdminRoute
            exact
            path="/secure/sms-templates/:auction_id"
            component={SmsTemplates}
          />
          <AdminRoute exact path="/secure/sent-sms/:auction_id" component={SentSmsList} />
          <AdminRoute
            exact
            path="/secure/sms-history/:auction_id/:smsId"
            component={SmsHistory}
          />
          <AdminRoute
            exact
            path="/secure/send-sms-notification/:auction_id"
            component={SendSmsNotification}
          />
          <AdminRoute
            exact
            path="/secure/send-sms-notification/:auction_id/:smsId"
            component={SendSmsNotification}
          />
          <AdminRoute
            exact
            path="/secure/create-sms-template/:auction_id"
            component={CreateSmsTemplate}
          />
          <AdminRoute
            exact
            path="/secure/update-sms-template/:auction_id/:templateId"
            component={UpdateSmsTemplate}
          />

          <AdminRoute
            exact
            path="/admin/website-configurations/:auction_id"
            component={WebsiteConfigurations}
          />
          <AdminRoute
            exact
            path="/admin/website-content/:pageSlug/:auction_id"
            component={AuctionWebsiteContent}
          />
          <AdminRoute
            exact
            path="/admin/website-logo/:auction_id"
            component={WebsiteLogoImage}
          />
          <AdminRoute
            exact
            path="/admin/website-images/:auction_id"
            component={WebsiteBackgroundImages}
          />

          <AdminRoute
            exact
            path="/admin/sponsor-images/:auction_id"
            component={WebsiteSponsorImages}
          />

          <AdminRoute
            exact
            path="/secure/donation-settings/:auction_id"
            component={DonationSettings}
          />

          <AdminRoute
            exact
            path="/secure/donations/:auction_id"
            component={DonationList}
          />

          <AdminRoute
            exact
            path="/secure/donations/:auction_id/invoices"
            component={DonationInvoices}
          />

          <AdminRoute
            exact
            path="/secure/bid-activity/:auction_id"
            component={ItemBidCountReport}
          />

          <AdminRoute
            exact
            path="/secure/bid-activity/:auction_id/:itemId"
            component={ItemBidReportList}
          />

          <AdminRoute
            exact
            path="/reports/winning-items/:auction_id"
            component={WinningItemPaymentList}
          />

          <AdminRoute
            exact
            path="/reports/donation/:auction_id"
            component={DonationReport}
          />

          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/forgot-password" component={ForgotPassword} />

          {/* If no route match, redirect to organizer dashboard */}
          <Redirect to="/dashboard" />
        </Switch>
      </React.Suspense>

      <FlashMessage />
    </BrowserRouter>
  );
};  

export default Router;
