import { isUnDefined } from './isDefined';

// prettier-ignore

const isDecimal = (value, precision = 2) => {
  const [base, cents = '', invalid] = value.split('.');

  if (
    /^[0-9.\b]*$/.test(value) &&
    isUnDefined(invalid) &&
    cents.length <= precision
  ) {
    return true;
  }

  return false;
};

/**
 * Export methods
 */
export { isDecimal };
