import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../store/actions/cmsPageActions';
import { getAccessTokenFromLS } from '../helpers';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

export const useAutoLogout = () => {
  let timer, accessToken;
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated || false);

  /// when component mounts, it adds an event listeners to the window
  /// each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc,
  /// the timer to logout user after 2 hours of inactivity resets.
  /// However, if none of the event is triggered within 2 hours,
  /// that is app is inactive, the app automatically logs out.

  useEffect(() => {
    if (isAuthenticated) {
      handleLogoutTimer();

      Object.values(events).forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer();
          validateAccessToken();
        });
      });
    }
  }, [isAuthenticated]);

  /// this function sets the timer that logs out the user after 2 hours
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      /// clears any pending timer.
      resetTimer();

      /// Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });

      /// logs out user
      dispatch(logoutUser());
    }, 7200000);
  };

  /// this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const validateAccessToken = () => {
    if ((accessToken = getAccessTokenFromLS())) {
      const decodedJwt = jwtDecode(accessToken);

      if (decodedJwt.exp * 1000 < Date.now()) {
        dispatch(logoutUser());
      }
    }
  };

  return () => {};
};
