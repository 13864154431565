export const API_URL =
  window.location.hostname === 'localhost'
    ? 'https://golfpro.lc'
    : 'https://app.perfectgolfevent.com';

export const SITE_IMAGE_PATH = API_URL + '/auction_image';
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY HH:mm';

export const DEFAULT_TIMEZONE = 'America/New_York';
export const ORGANIZER_REGISTRATION = 'ORGANIZER_REGISTRATION';
export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE';
export const SUB_DOMAIN_DETAIL = 'SUB_DOMAIN_DETAIL';
export const SWITCH_ORGANIZER_MODAL = 'SWITCH_ORGANIZER_MODAL';
export const ORGANIZER_SWITCH = 'ORGANIZER_SWITCH';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const USER_WINNIING_BIDS = 'USER_WINNIING_BIDS';
export const FRONTEND_REGISTRATION = 'FRONTEND_REGISTRATION';
export const CART_ITEM_QUANTITY = 'CART_ITEM_QUANTITY';

export const GET_AUCTION_PAYMENT_OPTIONS = 'GET_AUCTION_PAYMENT_OPTIONS';
export const CREATE_AUCTION_PAYMENT_OPTIONS = 'CREATE_AUCTION_PAYMENT_OPTIONS';
export const CREATE_AUCTION_PAYMENT_OPTIONS_REMOVE_DATA = 'CREATE_AUCTION_PAYMENT_OPTIONS_REMOVE_DATA';
